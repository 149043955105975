/* App.css */
.app-container {
  max-width: 750px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 185px;
}




.portfolio-banner {
  background-color: rebeccapurple;
  width: 100vw;
}

html {
  background-color: rgb(11, 11, 11);
}

.app-container2 {
  overflow-x: hidden;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 185px;
}

.game-info {
  display: flex;
  align-items: center;
  color: black;

}

.game-icon {
  width: 150px;
  /* Adjust the size as needed */
  height: 150px;
  margin-right: 20px;
  border-radius: 5px;
  border: solid 1px;
  border-color: grey;
}

.game-details {
  flex: 1;
  margin-top: -20px;
}

.game-description {
  color: black;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 15px;
  margin-inline-end: 15px;
  line-height: 25px;
  width: 100%;
  font-size: 20px;
  text-align: left;
  padding-bottom: 25px;
  padding-right: 15px;
  margin-left: 1px;
}

h1 {
  font-size: 32px;
}