.preview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 150px;
    scroll-behavior: smooth;
  }

  select {
    appearance: none;
    border-radius: 0;
    font-size: 15px;
    
  }

  input::placeholder {
    color: red
  }

  .footerLinks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
 
  }
  
  @media (max-width: 768px) {
    .footerLinks {
      flex-direction: column;
      align-items: flex-start;
      margin-left: -40px;
      margin-top: 10px
    }

  }

  .footerLinkA {
    flex: 0 0 auto;
    padding-bottom: 15px;
    padding-left: 15px;
    font-weight: bold;

  
  }

  a {
    text-decoration: none;
    color: #333

  
  }

  a:hover {
    color: red
  }
  
 



  .tag-container {
    display: flex;
    width: 250px;
  }

 

  .left-tag {
    background-color: blue;
    margin-right: 5px;
  }

  .right-tag {
    background-color: orange;

  }


  

  .icon-container {
    margin-bottom: 20px;
    img {
      max-width: 100%;
      height: auto;
      border: solid 2px;
      border-color: white;
      margin-top: 15px;
    }
  }
  .text-container {
    text-align: center;
    font-family: 'Poppins', sans-serif

    h1 {
      margin-bottom: 10px;
    }
    p {
      color: black;
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 15px;
      margin-inline-end: 15px;
      line-height: 25px;
      width: 95vw;
      font-size: 20px;
      text-align: left;
      padding-bottom: 25px;
      padding-left: 10px;
      padding-right: 10px;

    }
  }


  .screenshot-container-sideways {
    display: flex;
    margin-top: 25px;
    overflow-x: scroll;
    flex-direction: row;
    max-width: 200vw;
    margin-left: -14px;
  }

  .screenshot-container-sideways a {
    flex: 00 auto
  }

  .screenshot-container-sideways img {
    max-width: 95%;
    height: auto;
    max-height: 450px;
    margin: 15px;
    border: solid 1px grey;
    border-radius: 5px;
  }

  .screenshot-container-web {
    display: flex;
    margin-top: 25px;
    overflow-x: scroll;
    flex-direction: row;
    max-width: 200vw;
    margin-left: -9.5px;
  }

  .screenshot-container-web a {
    flex: 00 auto
  }

  .screenshot-container-web img {
    max-width: 96.5%;
    height: auto;
    max-height: 460px;
    margin: 15px;
    border: solid 1px grey;
    border-radius: 5px;
  }


  

  .screenshot-container {
    display: flex;
    margin-top: 25px;
    overflow-x: scroll;
    flex-direction: row;
    max-width: 350vw;
    margin-left: -14px;
  }

  .screenshot-container a {
    flex: 00 auto
  }

  .screenshot-container img {
    max-width: 500%;
    height: auto;
    max-height: 500px;
    margin: 15px;
    border: solid 1px grey;
    border-radius: 5px;
  }

  // .screenshot-container {
  //   display: flex;
  //   margin-top: 25px;
  //   overflow-x: scroll;
  //   flex-direction: row;
  //   max-width: 200vw;
  //   margin-left: -14px;
  //   a img {
  //     border: none;
  //   }
  //   img {
  //     max-width: 340px;
  //     height: 580px;
  //     margin: 10px;
  //     border-radius: 5px;
  //     border: solid 2px;
  //     border-color: white;
  //     border-radius: 5px;
  //     border: solid 1px;
  //     border-color: grey;
  //   }


  // }

  #screenshots {
        flex-wrap: wrap;

  }