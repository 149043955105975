.portfolioSelected {
    border-bottom: 'solid 2px';
    border-color: red;
}

.portfolioContainer {
    background: '#0b0b0b';
    overflow-x: hidden;
}

.portfolioPageHeader {
    color: white;
    margin-top: 25vh;
    text-align: center;
    font-size: 30px;
    font-family: "Poppins, sans-serif";
}

.portfolioDescription {
    color: white;
    margin-top: 50px;
    text-align: center;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.filterOptionsContainer {
    text-align: right;
    margin-bottom: 5px;
    margin-top: 10px;
    position: sticky;
    width: 100%;
    display: 'flex-wrap';
    padding-right: 2%;
    justify-content: center;
    padding-left: -80px;
}

.searchBar {
    background: black;
    color: red;
    height: 31px;
    width: 360px;
    padding: 2px;
    font-weight: bold;
    margin-right: 10px;
    border: 1px solid grey;
    border-radius: 0px;
}

.categoryFilterContainer {
    padding-top: 15px;
    padding-right: 8px;
}

.categoryFilter {
    background: black;
    color: red;
    width: 150px;
    border-radius: 0px;
    border: 1px solid grey;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 2px;
    font-weight: bold;
    font-size: 13px;
}

.redBrandingText {
    color: red
}

.gridContainerFullPortfolio {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    row-gap: 75px;
    column-gap: 50px;
    overflow-x: hidden;
    padding: "5vh 15px 175px 15px";
    padding-top: 50px;
    padding-bottom: 150px;
    
  }

  .noTextDecoration {
    text-decoration: none;
  }