.social-icons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Adjust spacing between icons */
    padding: 20px;
  }
  .social-icon {
    font-size: 24px; /* Adjust icon size */
    color: #333; /* Default color */
    transition: color 0.3s ease, transform 0.3s ease;
    text-decoration: none;
  }
  /* Hover effects */
  .social-icon:hover {
    transform: scale(1.1); /* Slight scale up on hover */
  }
  /* Optional: Specific colors for each platform */
  .social-icon:nth-child(1):hover { color: #FF0000; } /* YouTube - Red */
  .social-icon:nth-child(2):hover { color: #1877F2; } /* Facebook - Blue */
  .social-icon:nth-child(3):hover { color: #E4405F; } /* Instagram - Pink */
  .social-icon:nth-child(4):hover { color: #1DA1F2; } /* Twitter - Light Blue */
  .social-icon:nth-child(5):hover { color: #0077B5; } /* LinkedIn - Blue */
  .social-icon:nth-child(6):hover { color: #7289DA; } /* Discord - Purple */