@import url('https://fonts.googleapis.com/css2?family=Jersey+10&family=Pixelify+Sans&display=swap');



/* Ensure the video fills the screen */
.video-container {
    position: relative;
    width: 100%;
    height: 100dvh;
    overflow: hidden;
  }
  .background-video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the screen */
    position: absolute;
    top: 0;
    left: 0;
  }
  /* Center the overlay content */
  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }




  /* Pixel font styling for text */
  .textBig {
    font-family: "Pixelify Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 45px;
    margin-bottom: 20px; /* Add space between the text and the button */
     -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px;
  }

  .small-text {
    font-family: "Pixelify Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 35px;
  }

  .underline {
    border-bottom: solid 2px;
    border-color: red;
  }

  /* Button styling */
  .portfolio-button {
    width: 150px;
    padding: 10px 10px;
    font-size: 20px;
    font-family: "Pixelify Sans", sans-serif;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 50px; /* Oval shape */
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .portfolio-button:hover {
    background-color: #444; /* Slightly darker on hover */
  }


  @media only screen and (max-width: 1928px) {
    .overlay {
      width: 55%;
    }

    .small-text {
      font-size: 25px;
      line-height: 20px;

    }
  }

  @media only screen and (max-width: 1728px) {
    .overlay {
      width: 55%;
    }

    .small-text {
      font-size: 20px;
      line-height: 20px;

    }
  }

  @media only screen and (max-width: 1200px) {
    .overlay {
      width: 75%;
    }

    .small-text {
      font-size: 15px;
      line-height: 20px;

    }
  }

  @media only screen and (max-width: 750px) {

    .overlay {
      width: 95%;
    }
    .textBig {
      font-size: 30px;
    
    }

    .small-text {
      font-size: 14px;
      line-height: 20px;

    }

  }